@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf') format('otf'),
        url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-BoldItalic.otf') format('otf');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Regular.otf') format('otf');
        // url('../../fonts/Satoshi_Complete/Fonts/TTF/Satoshi-VariableItalic.ttf') format('ttf');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Light.otf') format('otf'),
        url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-LightItalic.otf') format('otf');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf') format('otf'),
        url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf') format('otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf') format('otf'),
        url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-MediumItalic.otf') format('otf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Light.otf') format('otf'),
        url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Light.otf') format('otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf') format('otf'),
        url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf') format('otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Regular.otf') format('otf'),
        url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Regular.otf') format('otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf') format('otf'),
        url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-MediumItalic.otf') format('otf');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf') format('otf'),
        url('../../fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf') format('otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}